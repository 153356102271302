<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<div slot="header" class="title">{{ $t('seller.customer.strategyTitle') }}</div>
			<div class="page-head">
				<div class="head-tabs">
					<el-tabs v-model="listQuery.type" @tab-click="handleClickTab">
						<el-tab-pane v-for="item in $t('seller.options.strategyTabs')" :key="item.value" :label="item.label" :name="item.value"></el-tab-pane>
					</el-tabs>
				</div>
				<div class="head-search">
					<el-input v-model="listQuery.title" :placeholder="$t('seller.placeholder.campaignTitleSearch')" size="small">
						<i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch"></i>
					</el-input>
				</div>
			</div>
			<div class="page-table">
				<table v-loading="listLoading" width="100%">
					<thead>
						<tr>
							<th>{{ $t('seller.tableHead.strategyInfo') }}</th>
							<th>{{ $t('seller.tableHead.contactInfo') }}</th>
							<th>{{ $t('seller.tableHead.status') }}</th>
							<th>{{ $t('seller.tableHead.strategyTime') }}</th>
							<th>{{ $t('seller.tableHead.crowd') }}</th>
							<th>{{ $t('seller.tableHead.handle') }}</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="(item,index) in pageList">
							<tr :key="index">
								<td>{{ item.title }}</td>
								<td style="min-width: 160px">
									<span class="item-contact" @click="handleVisibleContact(item)"><i :class="visibleIndex.includes(item.operate_id)?'el-icon-minus':'el-icon-plus'"></i>{{ getContact(item) }}{{ $t('seller.customer.strategyText1') }}</span>
								</td>
								<td><el-tag :type="item.status | typeFilter({0:'success',1:'success',2:'danger'})" size="mini">{{ item.status | typeFilter($t('seller.filters.strategyStatus')) }}</el-tag></td>
								<td>{{ item.create_time | parseTime() }}</td>
								<td>{{ item.crowd?item.crowd.title: ''}}</td>
								<td>
									<el-button type="text" @click="$router.push({ name: 'customerRegularDetail', params:{ id:item.operate_id }})" :disabled="item.type===1">{{ $t('seller.actions.details') }}</el-button>
									<el-button type="text" @click="$router.push({ name: 'customerStrategyData', params:{ id: item.operate_id }})">{{ $t('seller.actions.data') }}</el-button>
								</td>
							</tr>
							<template v-if="visibleIndex.includes(item.operate_id)">
								<tr v-if="item.is_poster === 1" :key="index+'_1'">
									<td></td>
									<td><el-tag size="mini">{{ $t('seller.pcdecorate.shop') }}</el-tag> {{ $t('seller.customer.strategyText2') }}</td>
									<td>
										<el-tag :type="item.poster_content.status | typeFilter({0:'warning',1:'success',2:'',3:'danger'})" size="mini">{{ item.poster_content.status | typeFilter($t('seller.filters.strategyItemStatus')) }}</el-tag>
									</td>
									<td>{{ item.poster_content.time_from | parseTime('{y}-{m}-{d}') }}</td>
									<td></td>
									<td>
										<el-button type="text" @click="handleStop(item.operate_id,item.poster_content.operate_touch_id)" :disabled="[2,3].includes(item.poster_content.status)">{{ $t('seller.actions.terminate') }}</el-button>
										<el-button type="text" @click="$router.push({ name: 'customerStrategyData', params:{ id: item.operate_id }})">{{ $t('seller.actions.data') }}</el-button>
									</td>
								</tr>
								<tr v-if="item.is_recommend === 1" :key="index+'_2'">
									<td></td>
									<td><el-tag size="mini">{{ $t('seller.pcdecorate.shop') }}</el-tag> {{ $t('seller.customer.strategyText3') }}</td>
									<td>
										<el-tag :type="item.recommend_content.status | typeFilter({0:'warning',1:'success',2:'',3:'danger'})" size="mini">{{ item.recommend_content.status | typeFilter($t('seller.filters.strategyItemStatus')) }}</el-tag>
									</td>
									<td>{{ item.recommend_content.time_from | parseTime('{y}-{m}-{d}') }}</td>
									<td></td>
									<td>
										<el-button type="text" @click="handleStop(item.operate_id,item.recommend_content.operate_touch_id)" :disabled="[2,3].includes(item.recommend_content.status)">{{ $t('seller.actions.terminate') }}</el-button>
										<el-button type="text" @click="$router.push({ name: 'customerStrategyData', params:{ id: item.operate_id }})">{{ $t('seller.actions.data') }}</el-button>
									</td>
								</tr>
							</template>
						</template>
						<tr v-if="!pageTotal > 0">
							<td colspan="6" align="center"><span style="color: #999;">暂无数据</span></td>
						</tr>
					</tbody>
				</table>
			</div>
			<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize" @pagination="getList"></pagination>
		</el-card>
	</el-scrollbar>
</template>

<script>
import { fetchrRategyList, stopSrategy } from "@/api/seller/customer"
import Pagination from '@/components/seller/pagination'
export default {
	components: { Pagination },
	data() {
		return {
			keyword: '',
			listQuery: {
				type: '-1',
				title: ''
			},
			listLoading: false,
			pageList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0,
			visibleIndex: []
		}
	},
	created () {
		this.getList()
	},
	methods: {
		getList() {
			this.listLoading = true
			const params = {
				...this.listQuery,
				page_index: (this.pageCurr - 1) *this.pageSize,
				page_size: this.pageSize
			}
			fetchrRategyList(params).then(response => {
				const{ data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		handleClickTab(tab, event) {
			this.pageCurr = 1
			this.getList()
		},
		handleSearch() {
			this.pageCurr = 1
			this.getList()
		},
		getContact(item) {
			var num = 0
			if(item.is_poster === 1) {
				num++
			}
			if(item.is_recommend === 1) {
				num++
			}
			return num
		},
		handleVisibleContact(item) {
			if(this.visibleIndex.includes(item.operate_id)) {
				this.visibleIndex.splice(this.visibleIndex.indexOf(item.operate_id),1)
			} else {
				this.visibleIndex.push(item.operate_id)
			}
		},
		handleStop(operate_id,operate_touch_id) {
			this.$confirm(this.$t('seller.confirm.terminateStrategy'), this.$t('seller.confirm.title'), {
				confirmButtonText: this.$t('seller.actions.confirm'),
				cancelButtonText: this.$t('seller.actions.cancel'),
				type: 'warning'
			}).then(() => {
				stopSrategy({operate_id: operate_id,operate_touch_id: operate_touch_id}).then(() => {
					this.getList()
				})
			}).catch(() => { })
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.page-head {
		display: flex;
		justify-content: space-between;
		.head-tabs {
			flex: 1;
			width: 0;
			::v-deep .el-tabs {
				.el-tabs__nav-wrap {
					&:after {
						height: 1px;
					}
				}
			}
		}
		.head-search {
			flex-shrink: 0;
			width: 240px;
			margin-left: 120px;
		}
	}
	.page-table {
		table {
			font-size: 14px;
			border-collapse: separate;
			border-spacing: 0 12px;
			border-radius: 10px;
			background: #f7faff;
			th {
				color: #999;
				padding: 12px 18px;
				text-align: left;
			}
			tbody {
				td {
					padding: 18px;
				}
				tr {
					background-color: #fff;
				}
			}
		}
		.item-contact {
			cursor: pointer;
			color: $--seller-primary;
			[class^=el-icon-] {
				margin-right: 9px;
				border-radius: 2px;
				border: 1px dashed $--seller-primary;
			}
		}
	}
}
.el-input__icon {
	cursor: pointer;
}
</style>