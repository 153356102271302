import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 用户
export function fetchCrowdData(data) {
	return request({
		url: '/seller/crowd/coreData',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCrowdList(data) {
	return request({
		url: '/seller/crowd/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCrowdInfo(data) {
	return request({
		url: '/seller/crowd/detail',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCrowdGoods(data) {
	return request({
		url: '/seller/crowd/pageGoods',
		method: 'post',
		data: param2Obj(data)
	})
}

export function createCrowd(data) {
	return request({
		url: '/seller/crowd/add',
		method: 'post',
		data: param2Obj(data)
	})
}

export function updateCrowd(data) {
	return request({
		url: '/seller/crowd/edit',
		method: 'post',
		data: param2Obj(data)
	})
}

export function crowdRegion(data) {
	return request({
		url: '/seller/crowd/regions',
		method: 'post',
		data: param2Obj(data)
	})
}

export function deleteCrowd(data) {
	return request({
		url: '/seller/crowd/delete',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchRegularInfo(data) {
	return request({
		url: '/seller/old/customer/detail',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchRegularData(data) {
	return request({
		url: '/seller/old/customer/core/data',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchRegularCrowd(data) {
	return request({
		url: '/seller/old/customer/crowd',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchRegularGoods(data) {
	return request({
		url: '/seller/old/customer/pageGoods',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchRegularCoupon(data) {
	return request({
		url: '/seller/old/customer/coupon',
		method: 'post',
		data: param2Obj(data)
	})
}

export function createRegular(data) {
	return request({
		url: '/seller/old/customer/save',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchMaterialCounpon(data) {
	return request({
		url: '/seller/operate/coupon/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function updateMaterialCounpon(data) {
	return request({
		url: '/seller/operate/coupon/set',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCustomCrowd(data) {
	return request({
		url: '/seller/custom/operate/listCrowd',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCustomCrowdList(data) {
	return request({
		url: '/seller/custom/operate/pageSelCrowd',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCollectCrowd(data) {
	return request({
		url: '/seller/custom/operate/pageCollectCrowd',
		method: 'post',
		data: param2Obj(data)
	})
}

export function collectCrowd(data) {
	return request({
		url: '/seller/custom/operate/collectCrowd',
		method: 'post',
		data: param2Obj(data)
	})
}

export function uncollectCrowd(data) {
	return request({
		url: '/seller/custom/operate/unCollectCrowd',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCustomCrowdInfo(data) {
	return request({
		url: '/seller/custom/operate/crowd',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchCustomCoupon(data) {
	return request({
		url: '/seller/custom/operate/coupons',
		method: 'post',
		data: param2Obj(data)
	})
}

export function createCustomOperate(data) {
	return request({
		url: '/seller/custom/operate/save',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchrRategyList(data) {
	return request({
		url: '/seller/operate/effect/page',
		method: 'post',
		data: param2Obj(data)
	})
}

export function fetchrRategyData(data) {
	return request({
		url: '/seller/operate/effect/data',
		method: 'post',
		data: param2Obj(data)
	})
}

export function stopSrategy(data) {
	return request({
		url: '/seller/operate/effect/stop',
		method: 'post',
		data: param2Obj(data)
	})
}
